.profile-page{
    padding: 0 3rem;

    
    .profile-card-1{
        padding: 2rem 2rem 3rem;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 5px;

        .main{
            display: flex;
            flex-direction: row;            
            align-items: center;

            .avatar{
                width: 5rem !important;
                height: 5rem !important;
                background-color: #5E72E4;
                font-size: 1.5rem;
            }

            .title{
                .name{
                font-size: 2rem;
                font-weight: 700;
                }

                .company{
                    font-size: 1rem;
                    font-weight: 500;

                }
            }

        }

        .email{
            margin-top: 2rem;
            font-weight: 700;
            
            span{
                font-weight: 500;
                
            }
        }
        .role{
            margin-top: 1rem;
            font-weight: 700;
            
            span{
                font-size: 0.9rem;
                font-weight: 500;
                text-transform: capitalize;
                background-color: #5e72e428;
                padding: 0.4rem 1.2rem;
                border-radius: 100px;
                color: #5E72E4;
                
            }
        }
        
    }

    .profile-card-2{
        padding: 2rem 2rem 3rem;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 5px;
        
        .title{
            font-weight: 700;
            font-size: 1.1rem;
            margin-bottom: 0.5rem;
        }

        .outlets{

            div{
                padding: 0.7rem 1rem;
                margin-top: 0.5rem;
                border-radius: 5px;
                background-color: #5e72e410;
            }
        }
    }

    @media (max-width: 990px) {

        .profile-card-1{
            box-shadow: none;
    
            
        }
        .profile-card-2{
            box-shadow: none;
    
            
        }
    }   
    @media (max-width: 768px) {
        padding: 0rem 0.5rem;

        .profile-card-1{
            padding: 1rem 2rem;
            box-shadow: none;
            border-radius: 5px;
    
            .main{
                display: flex;
                flex-direction: row;            
                align-items: center;
    
                .avatar{
                    width: 3rem !important;
                    height: 3rem !important;
                    background-color: #5E72E4;
                    font-size: 1.2rem;
                }
    
                .title{
                    .name{
                    font-size: 1.2rem;
                    font-weight: 700;
                    }
    
                    .company{
                        font-size: 0.9rem;
                        font-weight: 500;
    
                    }
                }
    
            }

            .email{
                margin-top: 2rem;
            }
            
        }
    }   
}

.profile-page-psudo{
    padding: 0 3rem;
    overflow: hidden;
    position: relative;
    
    .profile-card-1{
        padding: 2rem 2rem 3rem;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 5px;
        .main{
            display: flex;
            flex-direction: row;            
            align-items: center;

            .avatar{
                width: 5rem !important;
                height: 5rem !important;
                background-color: #f3f3f3;
                font-size: 1.5rem;
            }

            .title{
                .name{
                font-size: 1.5rem;
                font-weight: 700;
                background-color: #f3f3f3;
                margin-bottom: 0.5rem;
                border-radius: 30px;
                }

                .company{
                background-color: #f3f3f3;
                border-radius: 30px;
                font-size: 1rem;
                    font-weight: 500;

                }
            }

        }

        .email{
            margin-top: 2rem;
            font-weight: 700;
            
            span{
                font-weight: 500;
                border-radius: 30px;
                background-color: #f3f3f3;

            }
        }
        .role{
            margin-top: 1rem;
            font-weight: 700;
            
            span{
                font-size: 0.9rem;
                font-weight: 500;
                text-transform: capitalize;
                border-radius: 100px;
                color: #5E72E4;
                background-color: #f3f3f3;
                
            }
        }
        
    }

    .profile-card-2{
        padding: 2rem 2rem 3rem;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 5px;
        
        .title{
            font-weight: 700;
            font-size: 1.1rem;
            margin-bottom: 0.5rem;
        }

        .outlets{

            div{
                padding: 0.7rem 1rem;
                margin-top: 0.5rem;
                border-radius: 5px;
                background-color: #5e72e410;
            }
        }
    }

    @media (max-width: 990px) {

        .profile-card-1{
            box-shadow: none;
    
            
        }
        .profile-card-2{
            box-shadow: none;
    
            
        }
    }   
    @media (max-width: 768px) {
        padding: 0rem 0.5rem;

        .profile-card-1{
            padding: 1rem 2rem;
            box-shadow: none;
            border-radius: 5px;
    
            .main{
                display: flex;
                flex-direction: row;            
                align-items: center;
    
                .avatar{
                    width: 3rem !important;
                    height: 3rem !important;
                    background-color: #5E72E4;
                    font-size: 1.2rem;
                }
    
                .title{
                    .name{
                    font-size: 1.2rem;
                    font-weight: 700;
                    }
    
                    .company{
                        font-size: 0.9rem;
                        font-weight: 500;
    
                    }
                }
    
            }

            .email{
                margin-top: 2rem;
            }
            
        }
    }   
}