.shine-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
    z-index: 100;
    .shine{
        width: 50%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.1);
        transform: skewX(-20deg);
    }

    @keyframes loading {
        0%{
            transform: translateX(-150%);
        }
        50%{
            transform: translateX(-60%);
        }
        100%{
            transform: translateX(150%);
        }
    }
}